import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './RejectVaspModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useRejectVasp } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RejectVaspModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isRejectVaspModalOpen, rejectVaspDetails } = ui

  // Store Actions
  const { toggleRejectVaspModal, showAlert, setRejectVaspDetails } = actions

  // Hooks
  const { rejectVasp, rejectVaspData, isRejectVaspLoading, isRejectVaspError, isRejectVaspSuccess } = useRejectVasp()

  // Functions
  const toggleModal = () => {
    toggleRejectVaspModal()
  }
  const handleReject = () => {
    rejectVasp({ vasp_id: rejectVaspDetails.vasp_id })
  }

  // UseEffect
  useEffect(() => {
    if (rejectVaspData && isRejectVaspSuccess) {
      showAlert({ type: 'success', message: 'Successfully rejected VASP' })
      toggleModal()
      setRejectVaspDetails({ ...rejectVaspDetails, customer_status_id: -1 })
    }
  }, [rejectVaspData])
  useEffect(() => {
    if (isRejectVaspError) {
      showAlert({ type: 'error', message: 'An error occured in rejecting VASP' })
      toggleModal()
    }
  }, [isRejectVaspError])
  useEffect(() => {}, [])
  return (
    <Modal isOpen={isRejectVaspModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Reject Vasp
      </ModalHeader>
      <ModalBody>
        {isRejectVaspLoading && <ModalBodyWrapper>Rejecting...</ModalBodyWrapper>}
        {rejectVaspDetails?.vasp_id && !isRejectVaspLoading && !isRejectVaspError && (
          <ModalBodyWrapper>Are you sure you want to reject this vasp: {rejectVaspDetails.vasp_name}?</ModalBodyWrapper>
        )}
        {!isRejectVaspLoading && isRejectVaspError && (
          <ModalBodyWrapper>An error occured in rejecting vasp, please try again later.</ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={toggleModal} disabled={isRejectVaspLoading}>
            <Button color="secondary">Cancel</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleReject} disabled={isRejectVaspLoading}>
            <Button color="default">Reject</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
RejectVaspModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
RejectVaspModal.propTypes = {
  ui: PropTypes.shape({
    isRejectVaspModalOpen: PropTypes.bool,
    rejectVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    toggleRejectVaspModal: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setRejectVaspDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectVaspModal)
