/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Hooks
import { useUpdateTransfer } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  Wrapper,
  Container,
  ColWrapper,
  RowWrapper,
  FlowWrapper,
  FlowContainer,
  FlowDetailsWrapper,
  TransferDetailsWrapper,
  ButtonWrapper,
  BlockContainer,
} from './TravelRuleTableItemDetails.elements'

// Components
import {
  VerticalDotProgress,
  LoaderWrapper,
  LoaderCircle,
  Button,
  // ButtonSplitSelect
} from 'views/components'

// Layouts
import { TravelRuleTableItemSubHeader } from 'views/layouts'
import moment from 'moment'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TravelRuleTableItemDetails(props) {
  // Destructure
  const { transfer_status, transfer_details, isLoading, actions, table_data, ui, setTableData, getTransfer } = props
  const { updateTransferModalStatus } = ui
  const {
    // setIsIndividualRecipientDrawerOpen,
    // setIsEntityRecipientDrawerOpen,
    // setToBeUpdatedRecipient,
    // setIsPageTableUpdated,
    showAlert,
    toggleUpdateTransferModal,
    setUpdateTransferModalStatus,
    setUpdateTransferModalData,
  } = actions

  // hooks
  const [page, setPage] = useState(0)
  const [formattedFlow, setFormattedFlow] = useState(null)
  const [newStatus, setNewStatus] = useState()
  const [inAddress, setInAddress] = useState()
  const [outAddress, setOutAddress] = useState()
  const [inVaspAddress, setInVaspAddress] = useState()
  const [outVaspAddress, setOutVaspAddress] = useState()
  const { updateTransfer, transferUpdateData, isTransferUpdateSuccess } = useUpdateTransfer()

  // Functions
  // const handleUpdateRecipient = () => {
  //   setToBeUpdatedRecipient(transfer_details.out_record)
  //   if (transfer_details.out_record.recipient_type_id === 1) return setIsIndividualRecipientDrawerOpen(true)
  //   if (transfer_details.out_record.recipient_type_id === 2) return setIsEntityRecipientDrawerOpen(true)
  // }
  const formatFlow = () => {
    const steps = []
    let updated = false
    transfer_status.transfer_steps.forEach((item) => {
      const newItem = { leftLabel: item.date, rightLabel: item.label, disabled: false, failed: false }
      if (steps.length > 0) {
        if (steps[steps.length - 1].leftLabel !== null && item.date === null && table_data.transfer_status_id < 0) {
          updated = true
          const newerItem = {
            leftLabel: item.date,
            rightLabel: table_data.transfer_status_desc,
            disabled: false,
            failed: false,
          }
          newerItem.leftLabel = moment(table_data.last_modified).format('YYYY-MM-DDTHH:MM:SS')
          if (table_data.transfer_status_id === -3 || table_data.transfer_status_id === -2) {
            newerItem.failed = true
            steps.push(newerItem)
          }
        }
      }
      if (updated && newItem.failed === false) newItem.disabled = true
      steps.push(newItem)
    })
    setFormattedFlow(steps)
  }
  const handleUpdateTransferReq = (status_id) => {
    setNewStatus(status_id)
    toggleUpdateTransferModal()
    if (status_id === -3) {
      setUpdateTransferModalData({
        title: 'Reject Transfer',
        content: 'Are you sure you want to reject this transfer? This action is irreversible.',
      })
    }
    if (status_id === -2) {
      setUpdateTransferModalData({
        title: 'Cancel Transfer',
        content: 'Are you sure you want to cancel this transfer? This action is irreversible.',
      })
    }
    if (status_id === -1) {
      setUpdateTransferModalData({
        title: 'Confirm Transfer',
        content: 'Are you sure you want to confirm this transfer? This action is irreversible.',
      })
    }
  }
  const handleUpdateTransfer = () => {
    setUpdateTransferModalStatus()
    updateTransfer({ transfer_id: transfer_status.id, status_id: newStatus })
    setNewStatus()
  }
  const handleTransferUpdateData = () => {
    if (transferUpdateData && isTransferUpdateSuccess) {
      setTableData({ table_data, ...transferUpdateData })
      showAlert({ type: 'success', message: 'Successfully updated transfer' })
      getTransfer({ transfer_id: transfer_status.id })
    }
    if (transferUpdateData && !isTransferUpdateSuccess) {
      showAlert({ type: 'error', message: 'An error occured in updating record' })
    }
  }
  function handleInAddress() {
    let inAddressRaw = `${
      transfer_details.in_record?.address_street_no ? ` ${transfer_details.in_record?.address_street_no?.trim()}, ` : ''
    }${
      transfer_details.in_record?.address_street_name
        ? `${transfer_details.in_record?.address_street_name?.trim()}, `
        : ''
    }${
      transfer_details.in_record?.address_building_name
        ? `${transfer_details.in_record?.address_building_name?.trim()}, `
        : ''
    }${transfer_details.in_record?.address_floor ? `${transfer_details.in_record?.address_floor?.trim()}, ` : ''}${
      transfer_details.in_record?.address_room ? `${transfer_details.in_record?.address_room?.trim()}, ` : ''
    }${
      transfer_details.in_record?.address_department
        ? `${transfer_details.in_record?.address_department?.trim()}, `
        : ''
    }${transfer_details.in_record?.address_postbox ? `${transfer_details.in_record?.address_postbox?.trim()}, ` : ''}${
      transfer_details.in_record?.address_city_location
        ? `${transfer_details.in_record?.address_city_location?.trim()}, `
        : ''
    }${transfer_details.in_record?.address_city ? `${transfer_details.in_record?.address_city?.trim()}, ` : ''}${
      transfer_details.in_record?.address_region ? `${transfer_details.in_record?.address_region?.trim()}, ` : ''
    }${
      transfer_details.in_record?.address_district ? `${transfer_details.in_record?.address_district?.trim()}, ` : ''
    }${
      transfer_details.in_record?.address_postcode ? `${transfer_details.in_record?.address_postcode?.trim()}, ` : ''
    }${
      transfer_details.in_record?.address_country_code
        ? `${transfer_details.in_record?.address_country_code?.trim()}, `
        : ''
    }${
      transfer_details.in_record?.address_country_desc
        ? `${transfer_details.in_record?.address_country_desc?.trim()} `
        : ''
    }`
    if (inAddressRaw[inAddressRaw.length - 2] === ',') {
      inAddressRaw = inAddressRaw.slice(0, -2)
    }
    setInAddress(inAddressRaw)
  }
  function handleOutAddress() {
    let outAddressRaw = `${
      transfer_details.out_record?.address_street_no
        ? ` ${transfer_details.out_record?.address_street_no?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_street_name
        ? `${transfer_details.out_record?.address_street_name?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_building_name
        ? `${transfer_details.out_record?.address_building_name?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_floor?.trim()
        ? `${transfer_details.out_record?.address_floor?.trim()}, `
        : ''
    }${transfer_details.out_record?.address_room ? `${transfer_details.out_record?.address_room?.trim()}, ` : ''}${
      transfer_details.out_record?.address_department?.trim()
        ? `${transfer_details.out_record?.address_department?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_postbox ? `${transfer_details.out_record?.address_postbox?.trim()}, ` : ''
    }${
      transfer_details.out_record?.address_city_location
        ? `${transfer_details.out_record?.address_city_location?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_city?.trim() ? `${transfer_details.out_record?.address_city?.trim()}, ` : ''
    }${transfer_details.out_record?.address_region ? `${transfer_details.out_record?.address_region?.trim()}, ` : ''}${
      transfer_details.out_record?.address_district?.trim()
        ? `${transfer_details.out_record?.address_district?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_postcode ? `${transfer_details.out_record?.address_postcode?.trim()}, ` : ''
    }${
      transfer_details.out_record?.address_country_code
        ? `${transfer_details.out_record?.address_country_code?.trim()}, `
        : ''
    }${
      transfer_details.out_record?.address_country_desc
        ? `${transfer_details.out_record?.address_country_desc?.trim()} `
        : ''
    }`
    if (outAddressRaw[outAddressRaw.length - 2] === ',') {
      outAddressRaw = outAddressRaw.slice(0, -2)
    }
    setOutAddress(outAddressRaw)
  }
  function handleInVaspAddress() {
    let inAddressRaw = `${
      transfer_details.in_vasp?.address_street_no ? ` ${transfer_details.in_vasp?.address_street_no?.trim()}, ` : ''
    }${
      transfer_details.in_vasp?.address_street_name ? `${transfer_details.in_vasp?.address_street_name?.trim()}, ` : ''
    }${
      transfer_details.in_vasp?.address_building_name
        ? `${transfer_details.in_vasp?.address_building_name?.trim()}, `
        : ''
    }${transfer_details.in_vasp?.address_floor ? `${transfer_details.in_vasp?.address_floor?.trim()}, ` : ''}${
      transfer_details.in_vasp?.address_city ? `${transfer_details.in_vasp?.address_city?.trim()}, ` : ''
    }${transfer_details.in_vasp?.address_region ? `${transfer_details.in_vasp?.address_region?.trim()}, ` : ''}${
      transfer_details.in_vasp?.address_postcode ? `${transfer_details.in_vasp?.address_postcode?.trim()}, ` : ''
    }${
      transfer_details.in_vasp?.address_country_code
        ? `${transfer_details.in_vasp?.address_country_code?.trim()}, `
        : ''
    }`
    if (inAddressRaw[inAddressRaw.length - 2] === ',') {
      inAddressRaw = inAddressRaw.slice(0, -2)
    }
    setInVaspAddress(inAddressRaw)
  }
  function handleOutVaspAddress() {
    let outAddressRaw = `${
      transfer_details.out_vasp?.address_street_no ? ` ${transfer_details.out_vasp?.address_street_no?.trim()}, ` : ''
    }${
      transfer_details.out_vasp?.address_street_name
        ? `${transfer_details.out_vasp?.address_street_name?.trim()}, `
        : ''
    }${
      transfer_details.out_vasp?.address_building_name
        ? `${transfer_details.out_vasp?.address_building_name?.trim()}, `
        : ''
    }${transfer_details.out_vasp?.address_floor ? `${transfer_details.out_vasp?.address_floor?.trim()}, ` : ''}${
      transfer_details.out_vasp?.address_city ? `${transfer_details.out_vasp?.address_city?.trim()}, ` : ''
    }${transfer_details.out_vasp?.address_region ? `${transfer_details.out_vasp?.address_region?.trim()}, ` : ''}${
      transfer_details.out_vasp?.address_postcode ? `${transfer_details.out_vasp?.address_postcode?.trim()}, ` : ''
    }${
      transfer_details.out_vasp?.address_country_code
        ? `${transfer_details.out_vasp?.address_country_code?.trim()}, `
        : ''
    }`
    if (outAddressRaw[outAddressRaw.length - 2] === ',') {
      outAddressRaw = outAddressRaw.slice(0, -2)
    }
    setOutVaspAddress(outAddressRaw)
  }

  // UseEffects
  useEffect(() => {
    if (transfer_details) {
      handleInAddress()
      handleOutAddress()
      handleInVaspAddress()
      handleOutVaspAddress()
    }
  }, [transfer_details])
  useEffect(() => handleTransferUpdateData(), [transferUpdateData, isTransferUpdateSuccess])
  useEffect(() => {
    if (!isLoading && transfer_status) formatFlow()
  }, [transfer_status, isLoading])
  useEffect(() => {
    if (updateTransferModalStatus?.confirmed) handleUpdateTransfer()
  }, [updateTransferModalStatus])
  return (
    <Wrapper>
      <Container>
        {isLoading && (
          <LoaderWrapper>
            <LoaderCircle />
          </LoaderWrapper>
        )}
        {!isLoading && transfer_status && transfer_details && (
          <>
            {table_data.direction_id === 1 && table_data.transfer_status_id > -1 && (
              <ButtonWrapper>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    handleUpdateTransferReq(-3)
                  }}
                >
                  Reject
                </Button>
                <Button
                  value="Confirm"
                  onClick={() => {
                    handleUpdateTransferReq(-1)
                  }}
                />
                {/* <ButtonSplitSelect
                  value="Confirm"
                  onClick={() => {
                    handleUpdateTransferReq(-1)
                  }}
                  options={[{ value: 'Change', onClick: handleUpdateRecipient, endIcon: <ChangeIcon /> }]}
                /> */}
              </ButtonWrapper>
            )}
            {table_data.direction_id !== 1 && table_data.transfer_status_id > -1 && (
              <ButtonWrapper>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    handleUpdateTransferReq(-2)
                  }}
                >
                  Cancel
                </Button>
              </ButtonWrapper>
            )}

            <TravelRuleTableItemSubHeader page={page} setPage={setPage} />
            {page === 0 && transfer_status && formattedFlow !== null && (
              <FlowWrapper>
                <FlowContainer>
                  <BlockContainer>
                    <FlowDetailsWrapper>
                      <RowWrapper>
                        <Text>Date Initiated:</Text>
                        <Text>{moment(transfer_status?.created).format('YYYY-MM-DD')}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Transfer Number:</Text>
                        <Text>{transfer_status?.id}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Asset: </Text>
                        <Text>{table_data?.dti_name_long}</Text>
                      </RowWrapper>
                      {/* <RowWrapper>
                      <Text>Transfer set by:</Text>
                      <Text>{transfer_status?.created_by_desc}</Text>
                    </RowWrapper> */}
                      <RowWrapper>
                        <Text>Amount:</Text>
                        <Text>{transfer_status?.amount || 'NA'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Amount USD:</Text>
                        <Text>{transfer_status?.amount_usd || 'NA'}</Text>
                      </RowWrapper>
                      {/* <RowWrapper>
                        <Text>Date: </Text>
                        <Text>{transfer_status?.created}</Text>
                      </RowWrapper> */}
                    </FlowDetailsWrapper>
                  </BlockContainer>
                </FlowContainer>
                <FlowContainer>
                  <BlockContainer style={{ paddingBottom: '60px' }}>
                    <VerticalDotProgress headers={formattedFlow} />
                  </BlockContainer>
                </FlowContainer>
              </FlowWrapper>
            )}

            {page === 1 && (
              <TransferDetailsWrapper style={{ gridTemplateColumns: '1fr' }}>
                <RowWrapper>
                  <ColWrapper
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
                    }}
                  >
                    <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                      <Title>Beneficiary</Title>
                      <RowWrapper>
                        <Text>Account ID: </Text>
                        <Text>{transfer_details.out_record?.reference_id}</Text>
                        {/* {transfer_details.out_record?.reference_id && !transfer_details.out_account?.id && (
                          <Text>{transfer_details.out_record?.reference_id}</Text>
                        )}
                        {transfer_details.out_record?.reference_id && transfer_details.out_account?.id && (
                          <ExternalLink
                            exact
                            target="_blank"
                            to={`/directory/recipients/details/${transfer_details.out_account?.id}`}
                          >
                            <Text style={{ textAlign: 'right' }}>{transfer_details.out_record?.reference_id}</Text>
                            <ExternalLinkIcon />
                          </ExternalLink>
                        )} */}
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Name: </Text>
                        <Text>{transfer_details?.out_record?.display_name || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Wallet Address: </Text>
                        <Text>{transfer_status?.out_address_hash || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Physical Address: </Text>
                        <Text>{outAddress || '-'}</Text>
                      </RowWrapper>
                      {/* <RowWrapper>
                        <Text>
                          {transfer_details.out_record?.recipient_type_id === 1
                            ? 'Date of Birth:'
                            : 'Date of Incorporation:'}
                        </Text>
                        <Text>{transfer_details.out_record?.display_date}</Text>
                      </RowWrapper> */}
                    </BlockContainer>
                    <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                      <Title>Beneficiary VASP</Title>
                      <RowWrapper>
                        <Text>Legal Name: </Text>
                        <Text>{transfer_details.out_vasp?.name_legal || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Website: </Text>
                        <Text>{transfer_details.out_vasp?.website || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Legal Entity Identifier: </Text>
                        <Text>{transfer_details.out_vasp?.national_id || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Registration Country: </Text>
                        <Text>{transfer_details.out_vasp?.national_id_issue_country || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Physical Address: </Text>
                        <Text>{outVaspAddress || '-'}</Text>
                      </RowWrapper>
                    </BlockContainer>

                    <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                      <Title>Originator</Title>
                      <RowWrapper>
                        <Text>Account ID: </Text>
                        <Text>{transfer_details.in_record?.reference_id}</Text>
                        {/* {transfer_details.in_record?.reference_id && !transfer_details.in_account?.id && (
                          <Text>{transfer_details.in_record?.reference_id}</Text>
                        )}
                        {transfer_details.in_record?.reference_id && transfer_details.in_account?.id && (
                          <ExternalLink
                            exact
                            target="_blank"
                            to={`/directory/accounts/details/${transfer_details.in_account?.id}`}
                          >
                            <Text style={{ textAlign: 'right' }}>{transfer_details.in_record?.reference_id}</Text>
                            <ExternalLinkIcon />
                          </ExternalLink>
                        )} */}
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Name: </Text>
                        <Text>{transfer_details.in_record?.display_name}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>
                          {transfer_details?.in_account?.type_id === 2 ? 'Legal Entity Identifier' : 'National ID'}:{' '}
                        </Text>
                        <Text>{transfer_details.in_record?.national_id || '-'}</Text>
                      </RowWrapper>
                      {transfer_details?.in_account?.type_id === 1 && (
                        <>
                          <RowWrapper>
                            <Text>Date of Birth: </Text>
                            <Text>{transfer_details.in_record?.date_of_birth || '-'}</Text>
                          </RowWrapper>
                          <RowWrapper>
                            <Text>Place of Birth: </Text>
                            <Text>{transfer_details.in_record?.place_of_birth || '-'}</Text>
                          </RowWrapper>
                        </>
                      )}
                      {transfer_details?.in_account?.type_id === 2 && (
                        <RowWrapper>
                          <Text>Registration Country: </Text>
                          <Text>{transfer_details.in_record?.registration_country_code || '-'}</Text>
                        </RowWrapper>
                      )}
                      <RowWrapper>
                        <Text>Wallet Address: </Text>
                        <Text>{transfer_status?.in_address_hash || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Physical Address: </Text>
                        <Text>{inAddress || '-'}</Text>
                      </RowWrapper>
                      {/* <RowWrapper>
                        <Text>
                          {transfer_details.out_record?.recipient_type_id === 1
                            ? 'Date of Birth:'
                            : 'Date of Incorporation:'}
                        </Text>
                        <Text>{transfer_details.out_record?.display_date}</Text>
                      </RowWrapper> */}
                    </BlockContainer>
                    <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                      <Title>Originator VASP</Title>
                      <RowWrapper>
                        <Text>Legal Name: </Text>
                        <Text>{transfer_details.in_vasp?.name_legal || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Website: </Text>
                        <Text>{transfer_details.in_vasp?.website || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Legal Entity Identifier: </Text>
                        <Text>{transfer_details.in_vasp?.national_id || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Registration Country: </Text>
                        <Text>{transfer_details.in_vasp?.national_id_issue_country || '-'}</Text>
                      </RowWrapper>
                      <RowWrapper>
                        <Text>Physical Address: </Text>
                        <Text>{inVaspAddress || '-'}</Text>
                      </RowWrapper>
                    </BlockContainer>
                  </ColWrapper>
                </RowWrapper>
              </TransferDetailsWrapper>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
TravelRuleTableItemDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  transfer_status: [],
}

// Proptypes Validation
TravelRuleTableItemDetails.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    updateTransferModalStatus: PropTypes.shape({ confirmed: PropTypes.bool }),
  }),
  table_data: PropTypes.shape({
    direction_id: PropTypes.number,
    transfer_status_desc: PropTypes.string,
    last_modified: PropTypes.string,
    dti_name_long: PropTypes.string,
    transfer_status_id: PropTypes.number,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedRecipient: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    toggleUpdateTransferModal: PropTypes.func,
    setUpdateTransferModalStatus: PropTypes.func,
    setUpdateTransferModalData: PropTypes.func,
  }),
  transfer_status: PropTypes.arrayOf({
    transfer_number: PropTypes.string,
    transfer_author: PropTypes.string,
    transfer_steps: PropTypes.shape({}),
  }),
  transfer_details: PropTypes.any,
  isLoading: PropTypes.bool,
  setTableData: PropTypes.func,
  getTransfer: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableItemDetails)
