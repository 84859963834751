/* eslint-disable wrap-iife */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-extra-semi */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import React from 'react'
// { useEffect }
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
// import { parseJwt } from 'common/utils/jwt'

// Route Configurations
import Routes from 'common/config/route'

// Global Components
import GlobalComponents from 'views/global'

function Routing() {
  /// Check if user is logged in
  const userCredentials = JSON.parse(localStorage.getItem('userCredentials'))
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))

  // Checking if Access token has expired
  if (userCredentials) {
    if (Object.keys(userCredentials).length !== 0) {
      const expiryDate = new Date(userCredentials.expiry).getTime()
      const now = moment()
      const expirationDate = moment(expiryDate)

      // Compare if the current date is before the expiration date
      if (!now.isBefore(expirationDate)) {
        logout()
        return
      }

      const oneHour = 1 * 60 * 60 * 1000
      setTimeout(logout, oneHour)
      // const timeDiff = expirationDate.diff(now)
      // setTimeout(logout, timeDiff)
    }
  }

  /**
   * Validates if current authenticated user is authorized to view a route
   * @param  {} roles
   */
  const isUserAuthorized = (roles) => {
    // If route has role restriction, check if user role is authorized
    if (roles && roles.length) roles.some((role) => role === userCredentials.role)

    // Else route has no restriction give user access
    return true
  }

  /**
   * Conditionally selects route/component to render based on authentication or authorization
   * @param  {} isRouteProtected
   * @param  {} routeRoleAccess
   * @param  {} component
   */
  const renderRouteComponent = ({
    isRoutePublic,
    isRouteProtected,
    isRouteAdmin,
    routeRoleAccess,
    component,
    path,
  }) => {
    const emptyPath = path === undefined || path === '/'
    const localUserCredentials = JSON.parse(localStorage.getItem('userCredentials'))
    if (isRoutePublic) {
      return (
        <>
          {React.createElement(component)}
          <GlobalComponents />
        </>
      )
    }
    if (!isRouteProtected) {
      // Render public routes
      if ((!isLoggedIn || !localUserCredentials?.access_token) && emptyPath) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      if (!isLoggedIn || !localUserCredentials?.access_token) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
      // Redirect to home page if logged in user access public routes
      if (isLoggedIn && localUserCredentials?.access_token && emptyPath) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (isLoggedIn && localUserCredentials?.access_token) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
    }

    if (isRouteProtected) {
      // Redirect to login page if credentials are missing or not logged in
      if (!localUserCredentials?.access_token || !isLoggedIn) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      if ((!localUserCredentials?.access_token || !isLoggedIn) && emptyPath) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      // Render protected routes
      if (localUserCredentials?.role_id !== 0 && isRouteAdmin) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (localUserCredentials?.role_id !== 0 && isRouteAdmin && emptyPath) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (localUserCredentials?.access_token && isUserAuthorized(routeRoleAccess) && isLoggedIn) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
    }

    // Render not found route
    if (isRouteProtected === undefined || !path) {
      return <>{React.createElement(component)}</>
    }
  }

  // useEffect(() => {
  //   if (!userCredentials?.access_token) return
  //   const token = parseJwt(userCredentials?.access_token)
  //   console.log(token)
  //   if (token && token?.csi === 3) {
  //     console.log('CALLED')
  //     // Add the external script
  //     const script = document.createElement('script')
  //     script.async = true
  //     script.src = 'https://static.hotjar.com/c/hotjar-5255258.js?sv=6'
  //     script.type = 'text/javascript'
  //     document.head.appendChild(script)

  //     // Initialize Hotjar after the script is loaded
  //     script.onload = () => {
  //       ;(function (h, o, t, j, a, r) {
  //         h.hj =
  //           h.hj ||
  //           function () {
  //             ;(h.hj.q = h.hj.q || []).push(arguments)
  //           }
  //         h._hjSettings = { hjid: 5255258, hjsv: 6 }
  //         a = o.getElementsByTagName('head')[0]
  //         r = o.createElement('script')
  //         r.async = 1
  //         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  //         a.appendChild(r)
  //       })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
  //     }
  //     return () => {
  //       document.head.removeChild(script)
  //     }
  //   }
  // }, [userCredentials])
  return (
    <BrowserRouter>
      <Switch>
        {Routes.map(
          ({ isRoutePublic, isRouteProtected, isRouteAdmin, exact, roleAccess, routing: { path, component } }) => (
            <Route
              exact={exact}
              path={path}
              key={uuid()}
              render={() =>
                renderRouteComponent({ isRoutePublic, isRouteProtected, isRouteAdmin, roleAccess, component, path })
              }
            />
          )
        )}
      </Switch>
    </BrowserRouter>
  )
}
export default Routing

function logout() {
  localStorage.removeItem('userCredentials')
  window.location.reload()
}
