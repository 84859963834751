import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TableWrapper } from './TravelRule.elements'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

import {
  App,
  Header,
  Sidebar,
  Content,
  TravelRuleTable,
  PageWrapper,
  DemoTravelRuleTable,
  // NoAccess
} from 'views/layouts'
import { Container } from '../../components'

function TravelRule() {
  const [csi] = useState(getCustomerStatus())

  return (
    <App>
      <Sidebar />
      <Header pageTitle="Travel Rule" />

      {/* <NoAccess /> */}
      <Content>
        <PageWrapper>
          <Container>
            <TableWrapper>
              {(csi === 1 || csi === 2) && <TravelRuleTable />}

              {csi !== 1 && csi !== 2 && <DemoTravelRuleTable />}
            </TableWrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
TravelRule.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
TravelRule.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default TravelRule
