import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import { TableWrapper } from './DemoReportsTable.elements'

// Views
import { Table } from 'views/components'
import { ReportsAddressTableHeader, DemoReportsTableFilter, DemoReportsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoReportsTable(props) {
  // Destructure
  const { filters, actions } = props

  // Store States
  const { reportsAddressFilters } = filters
  const { toggleUpgradeModal } = actions

  const notifyForPayment = () => {
    notifyPaymentRequired(toggleUpgradeModal)
  }

  return (
    <TableWrapper>
      <Table totalItems={4} minWidth={900} tableFilters={reportsAddressFilters} setTableFilters={() => {}}>
        <DemoReportsTableFilter />
        <ReportsAddressTableHeader />
        <DemoReportsTableItem onClick={notifyForPayment} />
        <DemoReportsTableItem onClick={notifyForPayment} />
        <DemoReportsTableItem onClick={notifyForPayment} />
        <DemoReportsTableItem onClick={notifyForPayment} />
      </Table>
    </TableWrapper>
  )
}

// Default Props
DemoReportsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DemoReportsTable.propTypes = {
  filters: PropTypes.shape({
    reportsAddressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setReportsAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoReportsTable)
