import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableFilterFormWrapper } from './Filter.elements'

// Components
import { ButtonFilter } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoTransactionTableFilter(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUpgradeModal } = actions

  return (
    <TableFilterFormWrapper
      onClick={() => {
        notifyPaymentRequired(toggleUpgradeModal)
      }}
    >
      <ButtonFilter filter={<div />} />
    </TableFilterFormWrapper>
  )
}

// Default Props
DemoTransactionTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
DemoTransactionTableFilter.propTypes = {
  filters: PropTypes.shape({
    transactionFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      page_index: PropTypes.number,
      start_range: PropTypes.string,
      end_range: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTransactionTableFilter)
