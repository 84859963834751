import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { TableWrapper, TabsWrapper } from './Vasps.elements'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Views
import { Container, Tabs } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  VaspsTable,
  RequestedVaspsTable,
  PageWrapper,
  DirectorySubHeader,
  DemoVaspsTable,
  MPVaspsTable,
  // NoAccess
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const pages = [
  { label: 'VASPs', value: 'vasps' },
  { label: 'Requested VASPs', value: 'requested-vasps' },
]

function Vasps() {
  const [csi] = useState(getCustomerStatus())
  const [page, setPage] = useState(pages[0]?.value)

  return (
    <App>
      <Sidebar />
      <Header pageTitle="Directory" subHeader={<DirectorySubHeader />} />
      {/* <NoAccess /> */}
      <Content>
        <PageWrapper>
          <Container>
            <TableWrapper>
              {csi === 1 && (
                <TabsWrapper>
                  <Tabs pages={pages} page={page} setPage={setPage} />
                </TabsWrapper>
              )}
              {page === 'vasps' && csi === 1 && <VaspsTable />}
              {page === 'vasps' && csi === 2 && <MPVaspsTable />}
              {page === 'requested-vasps' && csi === 1 && <RequestedVaspsTable />}

              {csi !== 1 && csi !== 2 && <DemoVaspsTable />}
            </TableWrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Vasps.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Vasps.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Vasps)
