import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { COUNTRIES_LIST } from 'common/constants/countries'
import { NATIONAL_ID_TYPE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  TableButtonWrapper,
  TableRowWrapper,
  TablePanelWrapper,
} from './VaspDetailsForm.elements'

// Views
import {
  Form,
  FormTextField,
  FormSearchSelect,
  FormSelect,
  FormTextArea,
  FormDatePicker,
  AccordionTableItem,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef, ...rest } = props

  // Internal State
  const [initialData, setInitialData] = useState()
  // Forms
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(true)
  const [isAddressExpanded, setIsAddressExpanded] = useState(false)
  const [isFreeFormAddressExpanded, setIsFreeFormAddressExpanded] = useState(false)
  const [isNationalIDExpanded, setIsNationalIDExpanded] = useState(false)

  // Variables
  const userCredentials = JSON.parse(localStorage.getItem('userCredentials'))
  const validationSchema = Yup.object().shape({
    vasp_id: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    website: Yup.string().nullable(),
    name_business: Yup.string().nullable(),
    name_legal: Yup.string().nullable(),
    summary: Yup.string().nullable(),

    created: Yup.date().nullable(),
    last_modified: Yup.date().nullable(),

    address_line: Yup.string(),
    address_street_no: Yup.string(),
    address_street_name: Yup.string(),
    address_building_name: Yup.string(),
    address_floor: Yup.string().max(20).nullable(),
    address_room: Yup.string().max(20).nullable(),
    address_department: Yup.string().max(20).nullable(),
    address_postbox: Yup.string().max(20).nullable(),
    address_city_location: Yup.string().max(50).nullable(),
    address_city: Yup.string().max(50).nullable(),
    address_region: Yup.string().max(50).nullable(),
    address_district: Yup.string().max(50).nullable(),
    address_postcode: Yup.string().max(25).nullable(),
    address_country_code: Yup.string().max(2).nullable(),
    national_id_type_code: Yup.string().max(10).nullable(),
    national_id: Yup.string().max(35).nullable(),
    national_id_issue_country: Yup.string().max(2).nullable(),
    national_id_registration_authority: Yup.string().max(10).nullable(),
  })

  // Functions

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper {...rest}>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsDetailsExpanded(val)
            }}
            expandPanel={isDetailsExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isDetailsExpanded}>Business information</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Vasp ID" name="vasp_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Business Name" name="name_business" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Legal Name" name="name_legal" />
                  </FormInputGroupItem>
                  {userCredentials?.role_id === 0 && (
                    <FormInputGroupItem>
                      <FormTextField disabled={viewMode} label="Email" type="email" name="email_contact" />
                    </FormInputGroupItem>
                  )}
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Website" name="website" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormDatePicker disabled label="Created" name="created" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormDatePicker disabled label="Last Modified" name="last_modified" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextArea disabled={viewMode} label="Summary" name="summary" rows="4" />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />

          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsAddressExpanded(val)
            }}
            expandPanel={isAddressExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isAddressExpanded}>Structured address</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Street Number" name="address_street_no" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Street Name" name="address_street_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Building Name" name="address_building_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Floor" name="address_floor" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Room" name="address_room" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Department" name="address_department" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Postbox" name="address_postbox" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="City Location" name="address_city_location" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="City" name="address_city" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Region" name="address_region" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="District" name="address_district" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Zipcode" name="address_postcode" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSearchSelect
                      disabled={viewMode}
                      label="Country"
                      name="address_country_code"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />

          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsFreeFormAddressExpanded(val)
            }}
            expandPanel={isFreeFormAddressExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isFreeFormAddressExpanded}>Free form address</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextArea disabled={viewMode} label="Address Line" name="address_line" />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />
          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsNationalIDExpanded(val)
            }}
            expandPanel={isNationalIDExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isNationalIDExpanded}>National ID</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormSelect
                      disabled={viewMode}
                      label="National ID Type"
                      name="national_id_type_code"
                      options={NATIONAL_ID_TYPE_OPTIONS}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Legal Entity Identifier" name="national_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect
                      disabled={viewMode}
                      label="National ID Country Issuer"
                      name="national_id_issue_country"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField
                      disabled={viewMode}
                      label="National ID Registration Authority"
                      name="national_id_registration_authority"
                    />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
VaspDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    email: '',
    name_business: '',
    name_legal: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_id: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
  viewMode: true,
}

// Proptypes Validation
VaspDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    email: PropTypes.string,
    name_business: PropTypes.string,
    name_legal: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_id: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspDetailsForm)
