import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllVasps } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './VaspsTable.elements'

// Views
import { CardTable, Button, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { VaspsTableItem, VaspTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function VaspsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { vaspDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setVaspDirectoryFilters, setIsVaspDrawerOpen, setToBeUpdatedVasp, setIsPageTableUpdated } = actions

  // Variables
  const { sort, page, size } = vaspDirectoryFilters

  // States
  const [vaspsDataState, setVaspsDataState] = useState({ items: [], total: 0, page: 1 })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allVaspsData, getAllVasps, isAllVaspsLoading } = useFetchAllVasps()

  // Functions
  const fetchVasps = () => {
    getAllVasps({
      biz_id: userCredentials.Business_ID,
      sort,
      page,
      size,
    })
  }

  // UseEffects
  useEffect(() => {
    if (allVaspsData) setVaspsDataState(allVaspsData)
  }, [allVaspsData])

  useEffect(() => {
    if (vaspDirectoryFilters || isPageTableUpdated) {
      fetchVasps()
      setIsPageTableUpdated(false)
    }
  }, [vaspDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      <CardTable
        key={vaspsDataState?.page}
        totalItems={vaspsDataState.total}
        headerOptions={
          <Button
            onClick={() => {
              setToBeUpdatedVasp(null)
              setIsVaspDrawerOpen(true)
            }}
            size="small"
            startIcon={<PlusWhiteIcon />}
            variant="outlined"
          >
            Request Vasp
          </Button>
        }
        minWidth={900}
        filterComponents={<VaspTableFilter />}
        tableFilters={vaspDirectoryFilters}
        setTableFilters={setVaspDirectoryFilters}
      >
        {vaspsDataState.items.length > 0 && !isAllVaspsLoading ? (
          vaspsDataState.items.map((vaspDetails) => <VaspsTableItem key={vaspDetails.id} vaspDetails={vaspDetails} />)
        ) : (
          <>
            {isAllVaspsLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setVaspDirectoryFilters} />
            )}
            <div />
          </>
        )}
      </CardTable>
    </TableWrapper>
  )
}

// Default Props
VaspsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
VaspsTable.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspsTable)
