import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableWrapper } from './DemoVaspsTable.elements'

// Views
import { CardTable, Button } from 'views/components'

import { DemoDirectoryTableFilter, DemoVaspsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoVaspsTable(props) {
  // Destructure
  const { filters, actions } = props

  // Store States
  const { vaspDirectoryFilters } = filters

  // Store Actions
  const { toggleUpgradeModal } = actions

  // Functions
  const notifyForPayment = () => {
    notifyPaymentRequired(toggleUpgradeModal)
  }

  return (
    <TableWrapper>
      <CardTable
        key={1}
        totalItems={8}
        headerOptions={
          <Button
            onClick={() => {
              notifyForPayment()
            }}
            size="small"
            startIcon={<PlusWhiteIcon />}
            variant="outlined"
          >
            Request Vasp
          </Button>
        }
        minWidth={900}
        filterComponents={<DemoDirectoryTableFilter />}
        tableFilters={vaspDirectoryFilters}
        setTableFilters={() => {}}
      >
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
        <DemoVaspsTableItem onClick={notifyForPayment} />
      </CardTable>
    </TableWrapper>
  )
}

// Default Props
DemoVaspsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DemoVaspsTable.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoVaspsTable)
