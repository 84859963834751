/* eslint-disable react/no-unstable-nested-components */
import React, { cloneElement, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
// import { useFetchTransfer } from 'core/hooks/api'

// Constants
import { BLOCKCHAIN_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableColWrapper,
  TableRowContainer,
} from './DemoAddressesTableItem.elements'

// Views
import { AccordionTableItem, RiskBar, SkeletonText } from 'views/components'
import { DemoAddressesTableItemDetails } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoAddressesTableItem() {
  const [isExpanded, setIsExpanded] = useState(false)

  // Local States
  const [BarWidth] = useState(Math.floor(Math.random() * 100) + 1)

  return (
    <AccordionTableItem
      expandPanel={isExpanded}
      setIsExpanded={(val) => {
        setIsExpanded(val)
      }}
      panel={<DemoAddressesTableItemDetails BarWidth={BarWidth} />}
      button={
        <TableButtonWrapper>
          <TableRowWrapper>
            <TableRowContainer>
              {cloneElement(BLOCKCHAIN_MONO_SVGS.bitcoin)}
              <SkeletonText width="200" />
            </TableRowContainer>

            <TableRowContainer>
              <SkeletonText width="80" />
            </TableRowContainer>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableRowContainer>
              <SkeletonText width="40" />
              <RiskBar width={BarWidth} />
            </TableRowContainer>

            <TableRowContainer>
              <TableColWrapper>
                <SkeletonText width="80" height="17" style={{ marginBottom: '4px' }} />
                <SkeletonText width="40" height="12" borderRadius="3" />
              </TableColWrapper>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
    />
  )
}

// Default Props
DemoAddressesTableItem.defaultProps = {
  actions: {},
  data: {
    id: '',
    address: '',
    chain: '',
    symbol: '',
    risk_score: 0,
    balance_usd: 0,
    balance: 0,
    last_modified: '',
    link_type: 1,
    link_id: '',
  },
}

// Proptypes Validation
DemoAddressesTableItem.propTypes = {
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    setScannedAddressDetails: PropTypes.func,
    setAddressFilters: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    last_modified: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(DemoAddressesTableItem)
