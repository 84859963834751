/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, Container } from './DemoTransactionsTableItemDetails.elements'

// Internal Components
import { DetailsSummary } from './ItemDetailsComponents'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoTransactionsTableItemDetails(props) {
  const { BarWidth } = props

  return (
    <Wrapper>
      <Container>
        <DetailsSummary BarWidth={BarWidth} />
      </Container>
    </Wrapper>
  )
}

// Default Props
DemoTransactionsTableItemDetails.defaultProps = {
  transactionData: null,
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DemoTransactionsTableItemDetails.propTypes = {
  eventDetails: PropTypes.shape({
    inputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
    outputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
  }),
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    input_usd: PropTypes.number,
    output_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    sender_link_type: PropTypes.number,
    sender_link_id: PropTypes.string,
    recipient_link_type: PropTypes.number,
    recipient_link_id: PropTypes.string,
  }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
    setTransactionEventsFilters: PropTypes.func,
  }),
  senderAccountData: PropTypes.shape({}),
  recipientAccountData: PropTypes.shape({}),
  // businessNotes: PropTypes.arrayOf({}),
  // setBusinessNotes: PropTypes.func,
  filters: PropTypes.shape({
    transactionEventsFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTransactionsTableItemDetails)
