import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableWrapper } from './DemoAccountsTable.elements'

// Views
import { CardTable, Button, UncontrolledDropdown, DropdownToggle } from 'views/components'

import { DemoDirectoryTableFilter, DemoAccountsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoAccountsTable(props) {
  // Destructure
  const { filters, actions } = props

  // Store States
  const { accountDirectoryFilters } = filters

  // Store Actions
  const { toggleUpgradeModal } = actions

  // Functions
  const notifyForPayment = () => {
    notifyPaymentRequired(toggleUpgradeModal)
  }

  return (
    <TableWrapper>
      <CardTable
        key={1}
        totalItems={8}
        headerOptions={
          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button size="small" variant="outlined" startIcon={<PlusWhiteIcon />} onClick={notifyForPayment}>
                Account
              </Button>
            </DropdownToggle>
          </UncontrolledDropdown>
        }
        minWidth={900}
        filterComponents={<DemoDirectoryTableFilter />}
        tableFilters={accountDirectoryFilters}
        setTableFilters={() => {}}
      >
        <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
        <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
      </CardTable>
    </TableWrapper>
  )
}

// Default Props
DemoAccountsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DemoAccountsTable.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoAccountsTable)
