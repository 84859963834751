import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { TableWrapper } from './DemoTransactionsTable.elements'

// Views
import { AccordionTable } from 'views/components'

import { DemoTransactionTableFilter, DemoTransactionsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoTransactionsTable(props) {
  // Destructure
  const { filters, actions } = props

  // Store States
  const { transactionFilters } = filters

  const { setTransactionFilters } = actions

  // Local states
  const [pagination] = useState()

  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<DemoTransactionTableFilter />}
        tableFilters={transactionFilters}
        setTableFilters={setTransactionFilters}
      >
        <DemoTransactionsTableItem />
        <DemoTransactionsTableItem />
        <DemoTransactionsTableItem />
        <DemoTransactionsTableItem />
        <DemoTransactionsTableItem />
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
DemoTransactionsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DemoTransactionsTable.propTypes = {
  transaction: PropTypes.shape({
    scannedTransaction: PropTypes.instanceOf(Object),
    transactionChains: PropTypes.shape({}),
    defaultTransactionProvider: PropTypes.shape({}),
  }),
  filters: PropTypes.shape({
    transactionFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedTransaction: PropTypes.func,
    setScannedTransactionDetails: PropTypes.func,
    setTransactionChains: PropTypes.func,
    setDefaultTransactionProvider: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTransactionsTable)
