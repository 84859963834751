import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Styled Components
import { TableWrapper } from './Accounts.elements'

// Views
import { Container } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  AccountsTable,
  PageWrapper,
  DirectorySubHeader,
  DemoAccountsTable,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Accounts() {
  const [csi] = useState(getCustomerStatus())
  return (
    <App>
      <Sidebar />
      <Header pageTitle="Directory" subHeader={<DirectorySubHeader />} />
      <Content>
        <PageWrapper>
          <Container>
            <TableWrapper>
              {(csi === 1 || csi === 2) && <AccountsTable />}

              {csi !== 1 && csi !== 2 && <DemoAccountsTable />}
            </TableWrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Accounts.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Accounts.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
