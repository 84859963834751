import { authAxios } from 'common/utils/axios'

// Endpoint
import { MANAGER_PROFILE_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'
import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
export const newAuthAxios = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

const handler = authAxios

export const GetUsers = async () => {
  const response = await handler.get(MANAGER_PROFILE_ENDPOINTS.GET_USERS)
  const { data } = response
  return data
}

export const CreateUser = async ({ email, role_id, name_first, name_last }) => {
  const response = await handler.post(MANAGER_PROFILE_ENDPOINTS.CREATE_USER, {
    email,
    role_id,
    name_first,
    name_last,
  })
  const { data } = response
  return data
}

export const CreateUserActivationLink = async ({ user_id }) => {
  const response = await handler.post(`${MANAGER_PROFILE_ENDPOINTS.CREATE_USER_ACTIVATION_LINK}${user_id}`)
  const { data } = response
  return data
}

export const UpdateUser = async ({ user_id, email, role_id }) => {
  const response = await handler.patch(`${MANAGER_PROFILE_ENDPOINTS.UPDATE_USER}${user_id}`, {
    email,
    role_id,
  })
  const { data } = response
  return data
}
