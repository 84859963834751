/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableWrapper } from './DemoAddressesTable.elements'

// Views
import { AccordionTable } from 'views/components'

import { DemoAddressTableFilter, DemoAddressesTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoAddressesTable(props) {
  // Destructure
  const { filters, actions } = props

  // Store States
  const { addressFilters } = filters
  const { setAddressFilters } = actions

  // Local states
  const [pagination] = useState()

  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<DemoAddressTableFilter />}
        tableFilters={addressFilters}
        setTableFilters={setAddressFilters}
      >
        <DemoAddressesTableItem />
        <DemoAddressesTableItem />
        <DemoAddressesTableItem />
        <DemoAddressesTableItem />
        <DemoAddressesTableItem />
        <DemoAddressesTableItem />
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
DemoAddressesTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  address: { addressChains: null },
}

// Proptypes Validation
DemoAddressesTable.propTypes = {
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedAddress: PropTypes.func,
    setScannedAddressDetails: PropTypes.func,
    setAddressChains: PropTypes.func,
    setDefaultAddressProvider: PropTypes.func,
  }),
  address: PropTypes.shape({
    scannedAddress: PropTypes.shape({
      address_id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
      symbol: PropTypes.string,
      symbol_icon: PropTypes.string,
      financial_data: {
        provider_name: PropTypes.string,
        balance: PropTypes.number,
        balance_usd: PropTypes.number,
        spent: PropTypes.number,
        spent_usd: PropTypes.number,
        received: PropTypes.number,
        received_usd: PropTypes.number,
        transaction_count: PropTypes.number,
        latest_activity: PropTypes.string,
      },
      risk_data: {
        provider_name: PropTypes.string,
        risk_score: PropTypes.number,
        vasp_name_legal: PropTypes.string,
        vasp_name_business: PropTypes.string,
        vasp_website: PropTypes.string,
        vasp_category: PropTypes.string,
        api_result_id: PropTypes.string,
      },
      request_tz: PropTypes.string,
      modified_by_name: PropTypes.string,
      link_id: PropTypes.string,
      link_type: PropTypes.number,
    }),
    addressChains: PropTypes.shape({}),
    defaultAddressProvider: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoAddressesTable)
