import { authAxios } from 'common/utils/axios'

// Endpoint
import { PROFILE_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'
import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
export const newAuthAxios = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

const handler = authAxios

export const GetUserProfile = async ({ user_id }) => {
  const response = await handler.get(PROFILE_ENDPOINTS.READ_PROFILE, {
    params: {
      user_id,
    },
  })

  const { data } = response

  return data
}

export const UpdateProfile = async ({ data }) => {
  const response = await handler.patch(`${PROFILE_ENDPOINTS.UPDATE_PROFILE}`, data)

  return response.data
}

export const RequestEmailVerification = async () => {
  const response = await handler.get(PROFILE_ENDPOINTS.REQUEST_EMAIL_VERIFICATION)

  return response.data
}

export const VerifyEmail = async ({ auth_hash }) => {
  const response = await handler.post(`${PROFILE_ENDPOINTS.REQUEST_EMAIL_VERIFICATION}/${auth_hash}`)

  return response.data
}

export const GetActivationLink = async ({ auth_hash }) => {
  const response = await handler.get(`${PROFILE_ENDPOINTS.GET_ACTIVATION_LINK}${auth_hash}`)

  return response.data
}

export const ActivateUser = async ({ auth_hash, password }) => {
  const response = await handler.post(`${PROFILE_ENDPOINTS.ACTIVATE_USER}${auth_hash}`, { password })

  return response.data
}
