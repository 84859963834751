/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

import SearchIcon from 'assets/images/search'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import { TableFilterFormWrapper, SearchInput, SearchWrapper, IconWrapper } from './Filter.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoDirectoryTableFilter(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUpgradeModal } = actions

  return (
    <TableFilterFormWrapper>
      <SearchWrapper>
        <IconWrapper>
          <SearchIcon />
        </IconWrapper>
        <SearchInput
          value=""
          onChange={() => {}}
          placeholder="Search ID or Name..."
          onClick={() => {
            notifyPaymentRequired(toggleUpgradeModal)
          }}
          type="search"
          autocomplete="off"
        />
      </SearchWrapper>
    </TableFilterFormWrapper>
  )
}

// Default Props
DemoDirectoryTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
DemoDirectoryTableFilter.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoDirectoryTableFilter)
