import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// Constants
import { TRANSACTION_SORT_DATE_FILTER } from 'common/constants/filters'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, ButtonFilter, CustomDateRangePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TransactionTableFilter(props) {
  // Destructure
  const { filters, actions } = props
  const { transactionFilters } = filters
  const { sort, start_range, end_range } = transactionFilters

  // Store Actions
  const { setTransactionFilters } = actions

  // Functions
  // Filter Functions
  const handleDateSelect = async ({ start, end }) => {
    await setTransactionFilters({
      ...transactionFilters,
      start_range: start,
      end_range: end,
      page: 1,
    })
  }
  const handleSortSelect = async (value) => {
    if (sort !== value) {
      await setTransactionFilters({ ...transactionFilters, sort: value })
    }
  }

  return (
    <TableFilterFormWrapper>
      <ButtonFilter
        filter={
          <>
            <CustomDateRangePicker
              id="addressDateFilter"
              key={start_range}
              onApply={handleDateSelect}
              start={start_range}
              end={end_range}
              style={{ minWidth: '300px' }}
            />
            <SimpleSelect
              id="travelRuleSort"
              label="Sort By: "
              options={TRANSACTION_SORT_DATE_FILTER}
              onChange={handleSortSelect}
              value={sort}
            />
          </>
        }
      />
    </TableFilterFormWrapper>
  )
}

// Default Props
TransactionTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
TransactionTableFilter.propTypes = {
  filters: PropTypes.shape({
    transactionFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      page_index: PropTypes.number,
      start_range: PropTypes.string,
      end_range: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTableFilter)
