/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Chip,
  Text,
  Label,
  Title,
  SubTitle,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
  TableButtonWrapper,
  StatusContainer,
} from './VaspNotificationTableItem.elements'

// Views
import { Button, CardTableItem } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function VaspNotificationTableItem(props) {
  // Destructure
  const { vaspDetails: vasp_detail, actions, isRequestedVasp, ui } = props
  // Store State
  const { rejectVaspDetails } = ui

  // Actions
  const { toggleNotifyVaspModal, setNotifyVaspDetails, toggleRejectVaspModal, setRejectVaspDetails } = actions
  // State
  const [cardName, setCardName] = useState({})
  const [vaspDetails, setVaspDetails] = useState(vasp_detail)

  // Functions
  const handleNotifyVasp = () => {
    setNotifyVaspDetails({ vasp_id: vaspDetails?.id, vasp_name: vaspDetails?.name_legal })
    toggleNotifyVaspModal()
  }
  const handleRejectVasp = () => {
    setRejectVaspDetails({
      vasp_id: vaspDetails?.id,
      vasp_name: vaspDetails?.name_legal,
      customer_status_id: vaspDetails?.customer_status_id,
    })
    toggleRejectVaspModal()
  }

  // UseEffect
  useEffect(() => {
    if (vaspDetails?.name_legal) {
      const nameArr = vaspDetails?.name_legal.split(' ')
      const firstNameLength = Math.floor(nameArr.length / 2)
      const firstName = []
      for (let i = 0; i < firstNameLength; i++) {
        firstName.push(nameArr[i])
      }
      nameArr.splice(0, firstNameLength)
      setCardName({
        initials: vaspDetails?.name_legal[0] + vaspDetails?.name_legal[1],
        fullname: [firstName.join(' '), nameArr.join(' ')],
      })
    }
  }, [vaspDetails?.external_id])
  useEffect(() => {
    setVaspDetails(vasp_detail)
  }, [vasp_detail])
  useEffect(() => {
    if (rejectVaspDetails && rejectVaspDetails?.vasp_id === vaspDetails?.id) {
      setVaspDetails({ ...vaspDetails, ...rejectVaspDetails })
    }
  }, [rejectVaspDetails])

  return (
    <CardTableItem key={vaspDetails?.external_id} style={{ cursor: 'default' }}>
      <NavLink to={`/admin/details/vaspnotification/${vaspDetails?.id}`}>
        <TableRowWrapper>
          <Title>{cardName.initials || ''}</Title>
          <TableColContainer>
            <SubTitle>{cardName.fullname && cardName.fullname[0]}</SubTitle>
            <SubTitle>{cardName.fullname && cardName.fullname[1]}</SubTitle>
          </TableColContainer>
        </TableRowWrapper>
      </NavLink>

      <TableColWrapper>
        {vaspDetails?.customer_status_id !== null && vaspDetails?.customer_status_id !== undefined && (
          <TableRowContainer>
            <Label>Status: </Label>
            <StatusContainer>
              <Chip customer_status_id={vaspDetails.customer_status_id}>
                {vaspDetails?.customer_status_id === -1 && 'Rejected'}
              </Chip>
            </StatusContainer>
          </TableRowContainer>
        )}

        <TableRowContainer>
          <Label>Website: </Label>
          <Text>{vaspDetails?.website || 'NA'}</Text>
        </TableRowContainer>

        <TableRowContainer>
          <Label>Email: </Label>
          <Text>{vaspDetails?.email_contact || 'NA'}</Text>
        </TableRowContainer>

        <TableRowContainer>
          <Label>Latest update: </Label>
          <Text>{moment(vaspDetails?.created).format('MMMM DD YYYY, h:mm:ss')}</Text>
        </TableRowContainer>
      </TableColWrapper>
      <TableButtonWrapper>
        <Button variant="outlined" onClick={handleNotifyVasp} disabled={vaspDetails?.customer_status_id === -1}>
          Notify
        </Button>
        {isRequestedVasp && (
          <Button color="secondary" onClick={handleRejectVasp} disabled={vaspDetails?.customer_status_id === -1}>
            Reject
          </Button>
        )}
      </TableButtonWrapper>
    </CardTableItem>
  )
}

// Default Props
VaspNotificationTableItem.defaultProps = {}

// Proptypes Validation
VaspNotificationTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vaspDetails: PropTypes.any,
  isRequestedVasp: PropTypes.bool,
  actions: PropTypes.shape({
    toggleNotifyVaspModal: PropTypes.func,
    setNotifyVaspDetails: PropTypes.func,
    toggleRejectVaspModal: PropTypes.func,
    setRejectVaspDetails: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isRejectVaspModalOpen: PropTypes.bool,
    rejectVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspNotificationTableItem)
