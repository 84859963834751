import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Views
import { Container } from 'views/components'

import {
  App,
  Header,
  Sidebar,
  Content,
  PageWrapper,
  BlockchainAnalyticsSubHeader,
  AddressesTable,
  AddressSearchBar,
  DemoAddressesTable,
  DemoAddressSearchBar,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

function Address() {
  const [csi] = useState(getCustomerStatus())
  return (
    <App>
      <Sidebar />
      <Header subHeader={<BlockchainAnalyticsSubHeader />} pageTitle="Blockchain Analytics" />
      <Content>
        <PageWrapper>
          <Container>
            {(csi === 1 || csi === 2) && <AddressSearchBar />}
            {(csi === 1 || csi === 2) && <AddressesTable />}

            {csi !== 1 && csi !== 2 && <DemoAddressSearchBar />}
            {csi !== 1 && csi !== 2 && <DemoAddressesTable />}
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Address.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
Address.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    AddressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Address)
