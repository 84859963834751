// import { publicAxios } from 'common/utils/axios'
import { authAxios } from 'common/utils/axios'

// Endpoint
import { AUTH_ENDPOINTS, BASE_URL, LOCAL_URL } from 'common/constants/apiUrls'
import QueryString from 'qs'
import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
export const newAuthAxios = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

const handler = authAxios

export const Login = async ({ username, password }) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Accept: 'application/x-www-form-urlencoded',
    },
    data: QueryString.stringify({
      username,
      password,
    }),
    url: BASE_URL + AUTH_ENDPOINTS.LOGIN,
  }
  const response = await axios(options)

  const { data } = response
  // Adding expiry date to credentials
  const expDate = new Date()
  expDate.setHours(expDate.getHours() + 8)

  const dataWithExpDate = {
    ...data,
    expiry: expDate,
  }
  return dataWithExpDate
}
export const LoginTwoFa = async ({ auth_hash }) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Accept: 'application/x-www-form-urlencoded',
    },
    url: BASE_URL + AUTH_ENDPOINTS.LOGIN_TWOFA + auth_hash,
  }
  const response = await axios(options)

  const { data } = response
  // Adding expiry date to credentials
  const expDate = new Date()
  expDate.setHours(expDate.getHours() + 8)

  const dataWithExpDate = {
    ...data,
    expiry: expDate,
  }
  return dataWithExpDate
}

export const UpdatePassword = async ({ old_password, new_password }) => {
  const response = await handler.patch(AUTH_ENDPOINTS.UPDATE_USER_PASSWORD, {
    old_password,
    new_password,
  })

  const { data } = response

  return data
}
export const ResetPassword = async ({ email }) => {
  const response = await handler.get(AUTH_ENDPOINTS.RESET_PASSWORD, { params: { email } })

  const { data } = response

  return data
}
export const ResetPasswordConfirm = async ({ password, token }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.RESET_PASSWORD_CONFIRM}${token}`, { password })

  const { data } = response

  return data
}

// !FIND A BETTER WAY AROUND IT
export const GetUser = async ({ user_id }) => {
  const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token
  const customAuthAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const response = await customAuthAxios.get(AUTH_ENDPOINTS.GET_USER + user_id)

  const { data } = response

  return data
}

export const SignupVasp = async ({ token, email, password, phone, name_first, name_last, timezone_code }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.REGISTER}/${token}`, {
    token,
    email,
    password,
    phone,
    name_first,
    name_last,
    timezone_code,
  })

  const { data } = response

  return data
}
export const SignupTrial = async ({ email, password, name_first, name_last }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.SIGNUP_TRIAL}`, {
    email,
    password,
    name_first,
    name_last,
  })

  const { data } = response

  return data
}

export const ConfirmTrialAccount = async ({ hash }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.CONFIRMATION_TRIAL}/${hash}`)

  const { data } = response

  return data
}

export const GetVaspInfo = async (hash) => {
  const response = await handler.get(`${AUTH_ENDPOINTS.VASP_INFO}/${hash}`)

  const { data } = response

  return data
}

export const ConfirmVaspEmail = async ({ hash }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.CONFIRM_VASP_EMAIL}/${hash}`, {
    hash,
  })

  const { data } = response

  return data
}

export const ConfirmVaspUserEmail = async ({ hash }) => {
  const response = await handler.post(`${AUTH_ENDPOINTS.CONFIRM_VASP_USER_EMAIL}/${hash}`, {
    hash,
  })

  const { data } = response

  return data
}

export const GetUserAuth = async ({ hash }) => {
  const response = await handler.get(`${AUTH_ENDPOINTS.GET_USER_AUTH}${hash}`)

  const { data } = response

  return data
}
