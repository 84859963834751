import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TravelRuleTableWrapper } from './DemoTravelRuleTable.elements'

// Views
import { Button, AccordionTable } from 'views/components'

// Layouts
import { DemoTravelRuleTableFilter, DemoTravelRuleTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DemoTravelRuleTable(props) {
  // Destructure
  const { actions, filters } = props
  const { travelRuleFilters } = filters
  const { setTravelRuleFilters, toggleUpgradeModal } = actions
  return (
    <TravelRuleTableWrapper>
      <AccordionTable
        minWidth={700}
        totalItems={5}
        tableFilters={travelRuleFilters}
        setTableFilters={setTravelRuleFilters}
        filterComponents={<DemoTravelRuleTableFilter />}
        headerOptions={
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                notifyPaymentRequired(toggleUpgradeModal)
              }}
            >
              Report
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                notifyPaymentRequired(toggleUpgradeModal)
              }}
            >
              Transfer
            </Button>
          </>
        }
      >
        <DemoTravelRuleTableItem progress="20%" />
        <DemoTravelRuleTableItem progress="20%" />
        <DemoTravelRuleTableItem progress="20%" />
        <DemoTravelRuleTableItem progress="20%" />
        <DemoTravelRuleTableItem progress="20%" />
      </AccordionTable>
    </TravelRuleTableWrapper>
  )
}

// Default Props
DemoTravelRuleTable.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
DemoTravelRuleTable.propTypes = {
  actions: PropTypes.shape({
    setAddressChains: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
    setIsTransferDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleTransferReportModal: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.instanceOf(Object),
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTravelRuleTable)
