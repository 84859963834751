import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Constants
// import { TIMEZONES } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useSignupVasp, useGetUserAuth } from 'core/hooks/api'

// Views
import { Form, FormTextField, LoaderCircle, Button } from 'views/components'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormError,
  FormTitle,
  ButtonContainer,
} from './VaspSignupForm.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspSignupForm(props) {
  // Destructure
  const { formRef, actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { signupVasp, signupVaspData, signupVaspError, isSignupVaspLoading } = useSignupVasp()
  const { getUserAuth, getUserAuthData, getUserAuthError, isGetUserAuthLoading } = useGetUserAuth()
  const { token } = useParams()

  const [initialValues, setInitialValues] = useState()

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
    name_first: Yup.string().required('First Name is required'),
    name_last: Yup.string().required('Last Name is required'),
    timezone_code: Yup.string().required('Timezone is required'),
    token: Yup.string(),
  })
  const date = new Date()
  const offset = date.getTimezoneOffset()

  // Functions
  const handleOnSubmit = (values) => {
    signupVasp(values)
  }
  const handleUserAuthData = () => {
    setInitialValues({
      email: getUserAuthData?.email,
      password: '',
      phone: '',
      name_first: '',
      name_last: '',
      timezone_code: offset,
      token,
    })
  }

  // UseEffects
  useEffect(() => {
    if (signupVaspError) {
      if (signupVaspError?.response?.status < 500) {
        showAlert({ type: 'error', message: signupVaspError?.response?.data?.context })
      } else {
        showAlert({ type: 'error', message: 'An error occured in registering' })
      }
    }
  }, [signupVaspError])
  useEffect(() => {
    getUserAuth({ hash: token })
  }, [])
  useEffect(() => {
    if (getUserAuthData) handleUserAuthData()
  }, [getUserAuthData])
  return (
    <FormWrapper>
      {!signupVaspData && <FormTitle>Sign up to Ospree</FormTitle>}
      {getUserAuthError && <FormError>Link has expired.</FormError>}

      {signupVaspData && !signupVaspError && (
        <>
          <FormTitle>Congratulations! You have successfully signed up to Ospree. Login to your new account!</FormTitle>
          <ButtonContainer href="/login">
            <Button fullWidth>Login</Button>
          </ButtonContainer>
        </>
      )}

      {signupVaspData && signupVaspError && <FormError>An error occured in signing up.</FormError>}

      {isGetUserAuthLoading && <LoaderCircle />}
      {!signupVaspData && !signupVaspError && !getUserAuthError && initialValues && (
        <Form
          initialValues={initialValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Name" name="name_last" />
            </FormInputGroupItem>
            {/* <FormInputTimezoneGroupItem>
              <FormSelect label="Timezone" name="timezone_code" options={TIMEZONES} />
            </FormInputTimezoneGroupItem> */}
            {/* <FormInputGroupItem>
              <FormTextField label="Phone Number" name="phone" />
            </FormInputGroupItem> */}
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <Button fullWidth type="submit" disabled={isSignupVaspLoading}>
            {isSignupVaspLoading ? 'Submitting...' : 'Signup'}
          </Button>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
VaspSignupForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    email: '',
    name_first: '',
    name_last: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_id: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    timezone_code: '',
  },
}

// Proptypes Validation
VaspSignupForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_id: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    timezone_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspSignupForm)
