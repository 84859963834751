import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Styled Elements
import {
  Wrapper,
  BodyOverflowWrapper,
  BodyChildrenWrapper,
  HeaderOptionsWrapper,
  HeaderFilterButton,
  BodyWrapper,
} from './AccordionTable.elements'

// Views
import { Pagination } from 'views/components'

function AccordionTable(props) {
  // Destructure
  const { headerOptions, children, minWidth, totalItems, filterComponents, tableFilters, setTableFilters } = props

  // States
  const { size, page } = tableFilters
  const [csi] = useState(getCustomerStatus())

  // Variables

  // Functions

  const handlePageChange = async ({ page, size }) => {
    await setTableFilters({ ...tableFilters, page, size })
  }

  return (
    <>
      <Wrapper allowZeroExpanded>
        <HeaderFilterButton>
          {headerOptions && <HeaderOptionsWrapper>{headerOptions}</HeaderOptionsWrapper>}
        </HeaderFilterButton>

        {filterComponents && filterComponents}

        <BodyOverflowWrapper>
          <BodyWrapper minWidth={minWidth}>
            <BodyChildrenWrapper>{children}</BodyChildrenWrapper>
          </BodyWrapper>
        </BodyOverflowWrapper>
      </Wrapper>
      <Pagination
        itemsCountPerPage={size}
        totalItemsCount={totalItems}
        page={page}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
AccordionTable.defaultProps = {
  headerOptions: '',
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  tableFilters: {},
  setTableFilters: () => {},
}

// Proptypes Validation
AccordionTable.propTypes = {
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  tableFilters: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    page: PropTypes.number,
    size: PropTypes.number,
  }),
  setTableFilters: PropTypes.func,
}

export default AccordionTable
