/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, Container } from './DemoAddressesTableItemDetails.elements'

// Layouts
import { AddressDetailsSubHeader } from 'views/layouts'

// Internal Components
import { DetailsSummary, LinkedAccount } from './ItemDetailsComponents'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoAddressesTableItemDetails(props) {
  // Destructure
  const { isExpanded, BarWidth } = props

  // Local state
  const [page, setPage] = useState(0)

  return (
    <Wrapper>
      <Container>
        <AddressDetailsSubHeader page={page} setPage={setPage} />
        {page === 0 && <DetailsSummary isExpanded={isExpanded} BarWidth={BarWidth} />}
        {page === 1 && <LinkedAccount />}
      </Container>
    </Wrapper>
  )
}

// Default Props
DemoAddressesTableItemDetails.defaultProps = {
  addressData: null,
  actions: {},
  filters: {},
  ui: {},
  isExpanded: false,
}

// Proptypes Validation
DemoAddressesTableItemDetails.propTypes = {
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
  accountDataProp: PropTypes.shape({}),
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressDetails: PropTypes.func,
  }),

  isExpanded: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoAddressesTableItemDetails)
