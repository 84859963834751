/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import { TableFilterFormWrapper } from './Filter.elements'

// Components
import { Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoTravelRuleTableFilter(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUpgradeModal } = actions

  return (
    <TableFilterFormWrapper>
      <Search
        disabledOptions
        placeholder="Search Name..."
        onInputChange={() => {}}
        onClick={() => {
          notifyPaymentRequired(toggleUpgradeModal)
        }}
        isLoading={false}
      />
    </TableFilterFormWrapper>
  )
}

// Default Props
DemoTravelRuleTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
DemoTravelRuleTableFilter.propTypes = {
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.shape({
      sort: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      direction_id: PropTypes.string,
      asset_code: PropTypes.number,
      transfer_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTravelRuleTableFilter)
