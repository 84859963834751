/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
// import { BLOCKCHAIN_SYMBOL_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'
import // ADDRESS_REGEX_OPTIONS, ADDRESS_CHAIN_OPTIONS_MAPPING,
// TRANSACTION_CHAIN_OPTIONS,
// INTEGRATION_PARTNERS_ID,
'common/constants/formOptions'

// Hooks
import {
  useCreateOrUpdateTransaction,
  // useGetTransactionChainRecommendation
} from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  // SearchInputOptions,
  SearchBarContainer,
  Backdrop,
  CloseIconWrapper,
  Title,
  // ChainChoiceWrapper,
  // ChainChoice,
  ChoiceWrapper,
  // ChainChoice,
  ProviderChoiceWrapper,
  ProviderChoiceContainer,
} from './TransactionsSearchBar.elements'

// Views
import {
  TextField,
  Button,
  // SimpleSelect,
} from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TransactionsSearchBar(props) {
  // props
  const { actions, transaction } = props

  // destructure
  const { showAlert, setScannedTransaction, toggleInfoModal, setInfoModalDetails } = actions
  const {
    transactionChains,
    // defaultTransactionProvider
  } = transaction

  // local states
  const [isActive, setIsActive] = useState(false)
  const [transactionInput, setTransactionInput] = useState('')
  const [chain, setChain] = useState('')
  const [chainSymbol, setChainSymbol] = useState('')
  const [selectedProvider, setSelectedProvider] = useState()
  const [availableChains, setAvailableChains] = useState()
  const [allChains, setAllChains] = useState()
  // const [defaultProvider, setDefaultProvider] = useState()
  // const [matchedChains, setMatchedChains] = useState([])
  // const [otherChains, setOtherChains] = useState([])

  // hooks
  const {
    createOrUpdateTransaction,
    createOrUpdateTransactionData,
    createOrUpdateTransactionError,
    isCreateOrUpdateTransactionLoading,
  } = useCreateOrUpdateTransaction()
  // const {
  //   getTransactionChainRecommendation,
  //   getTransactionChainRecommendationData,
  //   // getTransactionChainRecommendationError,
  //   // isGetTransactionChainRecommendationLoading,
  // } = useGetTransactionChainRecommendation()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    const firstChild = Object.keys(availableChains?.[selectedProvider])[0]
    const firstChain = availableChains?.[selectedProvider]?.[firstChild]
    const provider_id = firstChain?.provider_id
    createOrUpdateTransaction({
      transaction: transactionInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      provider_id,
    })
    setTransactionInput('')
    setChain('')
    setChainSymbol()
    setSelectedProvider()
  }
  // function handleChainRecommendation(data) {
  //   const currentMatchedChains = []
  //   if (data?.length > 0) {
  //     data.map((item) => {
  //       currentMatchedChains.push({ disabled: false, value: item, label: item })
  //     })
  //   }
  //   if (currentMatchedChains.length === 1) {
  //     if (currentMatchedChains[0]?.value === 'near') {
  //       return setMatchedChains([])
  //     }
  //   }
  //   if (currentMatchedChains.length < 1) return setMatchedChains([])
  //   if (currentMatchedChains.length > 1) return setMatchedChains(currentMatchedChains)
  // }

  function handleTransactionChange(inputValue) {
    setTransactionInput(inputValue)
    // getTransactionChainRecommendation({ transaction: inputValue, chain })
    // handleChainMatch(inputValue)
  }

  function handleAvailableChains() {
    const providers = { FDBDM: { ...transactionChains?.financial?.FDBDM }, ...transactionChains?.risk }
    const allChains = {}
    let literallyAllChains = {}
    Object.keys(providers).forEach((providerCode) => {
      Object.keys(providers[providerCode]).forEach((chainCode) => {
        if (providers?.FDBDM?.[chainCode] && providerCode !== 'FDBDM') {
          allChains[providerCode] = { ...allChains[providerCode], [chainCode]: providers[providerCode][chainCode] }
          literallyAllChains = { ...literallyAllChains, [chainCode]: providers[providerCode][chainCode] }
        }
      })
    })
    setAvailableChains(allChains)
    setAllChains(literallyAllChains)
  }

  // useEffect(() => {
  //   if (getTransactionChainRecommendationData) handleChainRecommendation(getTransactionChainRecommendationData)
  // }, [getTransactionChainRecommendationData])
  // useEffect(() => chain && handleSubmit(), [chain])
  useEffect(() => {
    handleAvailableChains()
  }, [transactionChains])
  // useEffect(() => {
  //   if (defaultTransactionProvider) {
  //     setDefaultProvider(defaultTransactionProvider?.risk)
  //     setSelectedProvider(defaultTransactionProvider?.risk?.provider_code)
  //   }
  // }, [defaultTransactionProvider])
  useEffect(() => chain && selectedProvider && transactionInput && handleSubmit(), [selectedProvider])
  useEffect(() => !isActive && setTransactionInput(''), [isActive])
  useEffect(() => {
    if (createOrUpdateTransactionData) {
      setScannedTransaction(createOrUpdateTransactionData)
      setIsActive(false)
    }
  }, [createOrUpdateTransactionData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateTransactionError) {
      showAlert({ type: 'error', message: 'An error occured in screening transaction' })
    }
  }, [createOrUpdateTransactionError])
  useEffect(() => {
    if (isCreateOrUpdateTransactionLoading) {
      toggleInfoModal(true)
      setInfoModalDetails({
        title: 'Scanning Transaction',
        content: 'This process may take over 60 seconds, depending on the volume of data.',
        loading: true,
      })
    } else {
      toggleInfoModal(false)
      setInfoModalDetails()
    }
  }, [isCreateOrUpdateTransactionLoading])
  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={
                isCreateOrUpdateTransactionLoading ? 'loading...' : !isActive && 'Screen a transaction hash…'
              }
              onClick={() => {
                setIsActive(true)
              }}
              value={transactionInput}
              onChange={(e) => handleTransactionChange(e.currentTarget.value)}
            />
            {/* {isActive && (
              <SearchInputOptions>
                {chain && transactionInput && (
                  <SimpleSelect
                    id="transactionSearchOptionsProvider"
                    label="Provider"
                    placeholder={defaultProvider?.provider_code || 'Provider'}
                    options={
                      transactionChains?.risk &&
                      Object.keys(transactionChains?.risk)
                        ?.map((item) => {
                          if (transactionChains?.risk[item][chainSymbol]) {
                            return { label: item, value: item }
                          }
                        })
                        .filter((item) => item !== undefined)
                    }
                    onChange={(value) => {
                      setSelectedProvider(value)
                    }}
                    value={selectedProvider}
                    disabled={!transactionChains}
                  />
                )}
                <SimpleSelect
                  id="transactionSearchOptionsChain"
                  placeholder="Chain"
                  options={
                    transactionChains?.risk &&
                    Object.keys(transactionChains?.risk?.[selectedProvider])?.map((item) => {
                      const chainItem = transactionChains?.risk?.[selectedProvider][item]
                      return {
                        label: item,
                        value: chainItem?.chain_name,
                        SVG: BLOCKCHAIN_SYMBOL_MONO_SVGS[item],
                      }
                    })
                  }
                  onChange={(v) => setChain(v)}
                  disabled={!selectedProvider}
                />
                <Button disabled={!chain || !transactionInput} onClick={() => handleSubmit()}>
                  Screen
                </Button>
              </SearchInputOptions>
            )} */}
          </SearchInputWrapper>

          {isActive && transactionInput?.trim() && !isCreateOrUpdateTransactionLoading && (
            <ChoiceWrapper isActive={isActive}>
              {allChains && (
                <ProviderChoiceWrapper>
                  <Title>Select Chain</Title>
                  <ProviderChoiceContainer>
                    {Object.keys(allChains)?.map((key) => (
                      <Button
                        onClick={() => {
                          setChainSymbol(key)
                          setChain(allChains[key]?.chain_name)
                        }}
                        variant={chainSymbol === key ? 'primary' : 'outlined'}
                        key={key}
                      >
                        {allChains[key]?.chain_name}
                      </Button>
                    ))}
                  </ProviderChoiceContainer>
                </ProviderChoiceWrapper>
              )}
              {allChains && chain && transactionInput && (
                <ProviderChoiceWrapper>
                  <Title>Select Provider</Title>
                  <ProviderChoiceContainer>
                    {Object.keys(availableChains)?.map((key) => {
                      if (availableChains?.[key]?.[chainSymbol]) {
                        return (
                          <Button
                            onClick={() => {
                              setSelectedProvider(key)
                            }}
                            variant={selectedProvider === key ? 'primary' : 'outlined'}
                            key={key}
                          >
                            {key}
                          </Button>
                        )
                      }
                    })}
                  </ProviderChoiceContainer>
                </ProviderChoiceWrapper>
              )}
            </ChoiceWrapper>
          )}
          {/* {transactionInput && isActive && !isCreateOrUpdateTransactionLoading && (
            <ChainChoiceWrapper isActive={isActive}>
              {transactionChains?.length < 1 && (
                <Title>No chain has been identified for the provided transaction.</Title>
              )}
              {availableChains &&
                Object.keys(availableChains).map((item) => (
                  <ChainChoice
                    key={availableChains[item].symbol}
                    // style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => {
                      setChain(availableChains[item].chain_name)
                      // setChainSymbol(availableChains[item].symbol)
                    }}
                    active={availableChains[item].chain_name === chain}
                  >
                    {availableChains[item].symbol} - {availableChains[item].chain_name}
                  </ChainChoice>
                ))}
            </ChainChoiceWrapper>
          )} */}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
TransactionsSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
TransactionsSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedTransaction: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsSearchBar)
