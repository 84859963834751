import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import PropTypes from 'prop-types'

// Reducers
import { reducers } from 'core/store'

function AppStoreProvider(props) {
  // Destructure
  const { children } = props

  // Variables
  const persistConfig = {
    key: 'root',
    blacklist: ['alert', 'ui', 'filters', 'address', 'transaction'],
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, reducers)

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  })

  const persistor = persistStore(store)

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </StoreProvider>
  )
}

export default AppStoreProvider

// Proptypes Validation
AppStoreProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
}
