/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { PlusWhiteIcon, CloseIcon } from 'assets/images'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { RowWrapper, RowContainer, FlowWrapper } from '../../DemoTransactionsTableItemDetails.elements'

// Components
import { Button, UncontrolledDropdown, DropdownToggle } from 'views/components'
import RiskDetails from './RiskDetails'
import FinancialDetails from './FinancialDetails'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DetailsSummary(props) {
  // Destructure
  const { actions, BarWidth } = props

  const { toggleUpgradeModal } = actions

  return (
    <FlowWrapper>
      {/* -------FINANCIAL DATA------- */}
      <FinancialDetails />
      {/* -------RISK DATA------- */}
      <RiskDetails BarWidth={BarWidth} />
      {/* -------BOTTOM BUTTONS------- */}
      <RowWrapper>
        <RowContainer>
          <Button variant="outlined" size="small" disabled>
            Create Report
          </Button>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            size="small"
            endIcon={<CloseIcon />}
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Remove
          </Button>
          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button
                variant="outlined"
                size="small"
                endIcon={<PlusWhiteIcon />}
                onClick={() => {
                  notifyPaymentRequired(toggleUpgradeModal)
                }}
              >
                Add score
              </Button>
            </DropdownToggle>
          </UncontrolledDropdown>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
DetailsSummary.defaultProps = {
  actions: {},
}

// Proptypes Validation
DetailsSummary.propTypes = {
  actions: PropTypes.shape({
    toggleTransactionReportModal: PropTypes.func,
    setTransactionReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteTransactionModal: PropTypes.func,
    setIsLinkTransactionDrawerOpen: PropTypes.func,
    setTransactionLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setTransactionRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    toggleTransactionDetailsModalOpen: PropTypes.func,
    setTransactionModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSummary)
