/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Utils
// import { numberWithCommas } from 'common/utils/valueFormat'

// Assets
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'assets/images'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Components
import { CircularProgressBar, SkeletonText } from 'views/components'

// Styled Elements
import {
  Text,
  Title,
  ColContainer,
  RowWrapper,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  BlockContainer,
  InfoTitle,
  RiskContainer,
} from './RiskDetails.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RiskDetails(props) {
  const { isCreateOrUpdateTransactionLoading, actions, BarWidth } = props

  // Store
  const { toggleUpgradeModal } = actions

  return (
    <BlockContainer
      id="riskBlock"
      onClick={() => {
        notifyPaymentRequired(toggleUpgradeModal)
      }}
      style={{ marginTop: '15px', padding: '10px', borderWidth: 2 }}
    >
      <RowWrapper style={{ padding: 0 }}>
        {!isCreateOrUpdateTransactionLoading && (
          <GridRowContainer style={{ gap: '15px' }}>
            <RowContainer style={{ gap: '10px' }}>
              <RowContainer style={{ maxWidth: '250px', alignItems: 'center' }}>
                <BlockContainer style={{ display: 'flex', padding: '30px 0' }}>
                  <RiskContainer>
                    <CircularProgressBar key={123} id={`${123}-${1}`} size={130} progress={BarWidth}>
                      <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                        <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${BarWidth}%`}</Text>
                        <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                          Risk
                        </Text>
                      </ColContainer>
                    </CircularProgressBar>
                  </RiskContainer>
                </BlockContainer>
              </RowContainer>
              <RowContainer style={{ flex: 1 }}>
                <BlockContainer>
                  <Title style={{ textAlign: 'center' }}>
                    Input Risk <ArrowLeftCircleIcon />
                  </Title>
                  <TextRowContainer>
                    <InfoTitle>Risk Score</InfoTitle>
                    <Text style={{ border: 'none', fontSize: 15 }}>{`${BarWidth}`}/100</Text>
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                    <Text style={{ border: 'none', fontSize: 15 }}>{moment('2024-11-07').format('YYYY-MM-DD')}</Text>
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Source</InfoTitle>
                    <SkeletonText width="80" height="18" />
                  </TextRowContainer>
                </BlockContainer>
              </RowContainer>
            </RowContainer>

            <RowContainer style={{ gap: '10px' }}>
              <RowContainer style={{ maxWidth: '250px', alignItems: 'center' }}>
                <BlockContainer style={{ display: 'flex', padding: '30px 0' }}>
                  <RiskContainer>
                    <CircularProgressBar key={124} id={`${124}-${2}`} size={130} progress={BarWidth - 5}>
                      <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                        <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${BarWidth - 5}%`}</Text>
                        <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                          Risk
                        </Text>
                      </ColContainer>
                    </CircularProgressBar>
                  </RiskContainer>
                </BlockContainer>
              </RowContainer>
              <RowContainer style={{ flex: 1 }}>
                <BlockContainer>
                  <Title style={{ textAlign: 'center' }}>
                    Output Risk <ArrowRightCircleIcon />
                  </Title>
                  <TextRowContainer>
                    <InfoTitle>Risk Score</InfoTitle>
                    <Text style={{ border: 'none', fontSize: 15 }}>{`${BarWidth - 5}`}/100</Text>
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                    <Text style={{ border: 'none', fontSize: 15 }}>{moment('2024-11-07').format('YYYY-MM-DD')}</Text>
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Source</InfoTitle>
                    <SkeletonText width="80" height="18" />
                  </TextRowContainer>
                </BlockContainer>
              </RowContainer>
            </RowContainer>
          </GridRowContainer>
        )}
      </RowWrapper>
    </BlockContainer>
  )
}

// Default Props
RiskDetails.defaultProps = {
  isCreateOrUpdateTransactionLoading: false,
  actions: {},
}

// Proptypes Validation
RiskDetails.propTypes = {
  isCreateOrUpdateTransactionLoading: PropTypes.bool,
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskDetails)
