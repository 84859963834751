import React from 'react'
import {
  BitcoinMonoIcon,
  LitecoinMonoIcon,
  EthereumMonoIcon,
  BitcoinCashMonoIcon,
  BitcoinSvMonoIcon,
  CardanoMonoIcon,
  DashMonoIcon,
  NeoMonoIcon,
  PolkadotMonoIcon,
  RippleMonoIcon,
  SolanaMonoIcon,
  StellarMonoIcon,
  TezosMonoIcon,
  USDCMonoIcon,
  AlgorandMonoIcon,
  NearMonoIcon,
  DogecoinMonoIcon,
  PolygonMonoIcon,
  XdcMonoIcon,
  TronMonoIcon,
  FantomMonoIcon,
  OptimismMonoIcon,
} from 'assets/images/blockchainMonoSvgs'

export const BLOCKCHAIN_MONO_SVGS = {
  bitcoin: <BitcoinMonoIcon />,
  ethereum: <EthereumMonoIcon />,
  litecoin: <LitecoinMonoIcon />,
  'bitcoin-cash': <BitcoinCashMonoIcon />,
  'bitcoin-sv': <BitcoinSvMonoIcon />,
  cardano: <CardanoMonoIcon />,
  dash: <DashMonoIcon />,
  neo: <NeoMonoIcon />,
  polkadot: <PolkadotMonoIcon />,
  ripple: <RippleMonoIcon />,
  solana: <SolanaMonoIcon />,
  stellar: <StellarMonoIcon />,
  tezos: <TezosMonoIcon />,
  usdc: <USDCMonoIcon />,
  algorand: <AlgorandMonoIcon />,
  near: <NearMonoIcon />,
  doge: <DogecoinMonoIcon />,
  polygon: <PolygonMonoIcon />,
  xdc: <XdcMonoIcon />,
  tron: <TronMonoIcon />,
  fantom: <FantomMonoIcon />,
  optimism: <OptimismMonoIcon />,
}
export const BLOCKCHAIN_SYMBOL_MONO_SVGS = {
  BTC: <BitcoinMonoIcon />,
  ETH: <EthereumMonoIcon />,
  LTC: <LitecoinMonoIcon />,
  BCH: <BitcoinCashMonoIcon />,
  BSV: <BitcoinSvMonoIcon />,
  ADA: <CardanoMonoIcon />,
  DASH: <DashMonoIcon />,
  NEO: <NeoMonoIcon />,
  DOT: <PolkadotMonoIcon />,
  XRP: <RippleMonoIcon />,
  SOL: <SolanaMonoIcon />,
  XLM: <StellarMonoIcon />,
  XTZ: <TezosMonoIcon />,
  USDC: <USDCMonoIcon />,
  ALGO: <AlgorandMonoIcon />,
  NEAR: <NearMonoIcon />,
  DOGE: <DogecoinMonoIcon />,
  MATIC: <PolygonMonoIcon />,
  XDC: <XdcMonoIcon />,
  TRX: <TronMonoIcon />,
  FTM: <FantomMonoIcon />,
  OP: <OptimismMonoIcon />,
}
