import { authAxios } from 'common/utils/axios'

// Endpoint
import { CASES_ENDPOINTS } from 'common/constants/apiUrls'

const handler = authAxios

export const GetAutoCompleteCustomer = async ({ customer_id }) => {
  const response = await handler.get(CASES_ENDPOINTS.AUTOCOMPLETE_CUSTOMER_SEARCH, {
    params: {
      customer_id,
    },
  })

  const data = response.data.content

  return data
}

export const GetCustomer = async ({ user_id, moduleType, query }) => {
  const response = await handler.get(CASES_ENDPOINTS.CUSTOMER_SEARCH, {
    params: {
      user_id,
      [moduleType]: query,
    },
  })

  const data = response.data.content[0]

  return data
}
